<template>
  <v-row dense>
    <v-col>
      <v-card elevation="0" outlined>
        <v-card-title>워킹그룹 관리</v-card-title>
        <v-card-text>
          <v-data-table disable-filtering disable-sort single-select dense
                        :items="items" :headers="headers"
                        :loading="loading" item-key="_id"
                        mobile-breakpoint="0" :footer-props="vs.footerPropsErsTable"
                        @click:row="selectItem"
          >
            <template v-slot:item.matches="{item}">
              {{item.distMatches.join(',') || ''}}
            </template>
            <template v-slot:item.distBase="{item}">
              {{ distOptions.find(e => e.value === item.distBase).text || item.distBase }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-row>
        <v-col>
          <v-card elevation="0" outlined width="60%"> <!-- detail -->
            <v-card-subtitle><v-btn small color="warning" @click.stop="setCreateMode">신규등록</v-btn></v-card-subtitle>
            <v-card-text>
              <v-row dense>
                <v-col><v-text-field dense :readonly="!isCreateMode" v-model="selected.code" label="코드" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-text-field dense v-model="selected.name" label="그룹명" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-select dense v-model="selected.distBase" :items="distOptions" label="분배기준" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-select dense return-object v-model="customer" :items="customerOptions" item-text="custName" label="매치 리스트" /></v-col>
                <v-col cols="auto"><v-btn small color="info" @click.stop="addCustomer">추가</v-btn></v-col>
                <v-col>
                  <v-data-table disable-filtering disable-sort dense
                                :items="customers" :headers="customerHeaders"
                                :items-per-page="5" item-key="custCode"
                                mobile-breakpoint="0" hide-default-footer
                  >
                    <template v-slot:item.action="{item}">
                      <v-btn small color="error" @click.stop="removeCustomer(item.custCode)">삭제</v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn small color="info" @click.stop="saveItem" :disabled="!selected.code">저장</v-btn>
              <v-btn small color="error" @click.stop="deleteItem" :disabled="!selected.code">삭제</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import api from '@/services/api'
import vs from '@/utils/viewSupport'
import { mapGetters } from 'vuex'
import notify from '@/utils/notify'

export default {
  name: 'Workgroup',
  metaInfo: {
    title: '워킹그룹 관리'
  },
  data() {
    return {
      vs,
      loading: false,
      items: [],
      selected: {
      },
      headers: [
        { text: '코드', value: 'code' },
        { text: '그룹명', value: 'name' },
        { text: '센터명', value: 'center.centerName' },
        { text: '분배기준', value: 'distBase' },
        { text: '매치코드', value: 'matches' },
        { text: '등록일', value: 'createdAt' },
        { text: '수정일', value: 'updatedAt' },
        { text: '등록자', value: 'regId' },
        { text: '수정자', value: 'updId' }
      ],
      distOptions: [
        { text: '고객코드', value: 'custCode' },
        { text: '요청위치', value: 'location' }
      ],
      customerOptions: [],
      customer: null,
      customers: [],
      customerHeaders: [
        { text: '코드', value: 'custCode' },
        { text: '고객명', value: 'custName' },
        { text: '', value: 'action' },
      ],
      mode: 'V'
    }
  },
  methods: {
    list() {
      this.loading = true
      api.getWorkgroup().then(r => { this.items = r.result }).finally(() => { this.loading = false })
    },
    listCustomerOptions() {
      api.listCustomers().then(r => {
        this.customerOptions = r.result
      })
    },
    selectItem(item) {
      this.selected = item
      this.customers = []
      this.customer = null
      if (item.distMatches && item.distMatches.length > 0) {
        item.distMatches.forEach(match => {
          const customer = this.customerOptions.find(e => e.custCode === match)
          if (customer) this.customers.push(customer)
        })
      }
      this.mode = 'E'
    },
    setCreateMode() {
      this.customers = []
      this.customer = null
      this.selected = { distBase: 'location' }
      this.selected.center = this.loggedInUser.counselor.center._id
      this.mode = 'C'
    },
    saveItem() {
      if (this.selected) {
        this.selected.distMatches = []
        if (this.customers.length > 0) {
          this.customers.forEach(e => {
            this.selected.distMatches.push(e.custCode)
          })
        }
        if (this.isCreateMode) {
          api.postWorkgroup(this.selected).then(r => {
            notify.showMessage('등록 되었습니다.')
            this.list()
            this.setCreateMode()
          })
        } else {
          api.putWorkgroup(this.selected._id, this.selected).then(r => {
            notify.showMessage('수정 되었습니다.')
            this.list()
            this.setCreateMode()
          })
        }
      }
    },
    deleteItem() {
      if (this.selected) {
        api.deleteWorkgroup(this.selected._id).then(() => {
          notify.showMessage('삭제 되었습니다.')
          this.list()
          this.setCreateMode()
        })
      }
    },
    addCustomer() {
      if (this.customer) {
        if (this.customers.find(e => e.custCode === this.customer.custCode)) return
        this.customers.push(this.customer)
      }
    },
    removeCustomer(custCode) {
      const idx = this.customers.findIndex(e => e.custCode === custCode)
      if (idx > -1) this.customers.splice(idx, 1)
    }
  },
  async mounted() {
    await this.listCustomerOptions()
    this.list()
    this.setCreateMode()
  },
  created() {
    this.$store.commit('setAppBarTitle', '워킹그룹 관리')
  },
  computed: {
    ...mapGetters(['loggedInUser', 'role']),
    isViewMode() {
      return this.mode === 'V'
    },
    isCreateMode() {
      return this.mode === 'C'
    },
    isEditMode() {
      return this.mode === 'E'
    },
    isManager() {
      return this.role === 'CD'
    }
  }
}
</script>

<style scoped>
.v-data-table >>> thead tr th {
  padding: 6px !important;
  font-weight: bold !important;
  background-color: black !important;
  color: white !important;
  font-size: 12px !important;
}

.v-data-table >>> tbody tr td {
  padding: 0px 0px 0px 0px !important;
  font-size: 11px !important;
}

>>> div.v-select__selections > div {
  font-size:14px !important;
}

</style>
